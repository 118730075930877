import * as React from 'react';
import { Link } from 'gatsby';

import Header from '../components/header';

// styles
const pageStyles = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#232129',
  padding: '120px 96px',
  flexGrow: 1,
  fontFamily: '-apple-system, Roboto, sans-serif, serif'
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320
};

const paragraphStyles = {
  marginBottom: 48
};
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4
};

// markup
const NotFoundPage = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Header />

      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{' '}
          <span role='img' aria-label='Pensive emoji'>
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          <br />
          <Link to='/'>Go home</Link>.
        </p>
      </main>
    </div>
  );
};

export default NotFoundPage;
